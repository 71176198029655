.form-group {
  margin-bottom: 0px;
}
.form-group-1 {
  width: 200px;
  margin-right: 20px;
}

.input-styling {
  border-radius: 4px;
  border: solid 0.5px #bbc0c6;
  height: 30px;
  color: $ink;
  font-family: Gotham-Medium;
  font-size: 14px;
  line-height: 1;

  &::placeholder {
    color: $ink-lighter;
    font-family: Gotham-Medium;
    font-size: 14px;
    line-height: 1;
    opacity: 0.46;
  }
}

.select-input-styling {
  border-radius: 4px;
  //border:solid 0.5px #bbc0c6;
  height: 30px;
  color: $ink;
  // font-family:Gotham-Medium;
  font-size: 14px;
  line-height: 1;

  &::placeholder {
    color: $ink-lighter;
    font-family: Gotham-Medium;
    font-size: 14px;
    line-height: 1;
    opacity: 0.46;
  }
}

.span-styling {
  span {
    border-radius: 8px;
    border: solid 1px $teal-lighter;
    background: $sky-lighter;
  }
}

.label-bottom {
  margin-bottom: 6px;
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: $ink-lighter;
}

.label-select {
  color: #333853;
  font-size: 14px;
  margin-bottom: 10px;
}

.custom-popover {
  background-color: transparent;
  border-color: transparent;
  padding: 0px;
}

.custom-popover:hover {
  background-color: transparent;
  border-color: transparent;
}

.custom-popover:focus {
  background-color: transparent;
  border-color: transparent;
}

.custom-popover-body {
  background: $white;
  border-radius: 8px;
  padding: 0px;
}

.popper-card {
  border: solid 1px $sky-lighter;
  border-radius: 8px;
}

.popper-card-child {
  border-bottom: solid 1px $teal-lighter;
  padding: 18px 80px 18px 22px;
}
.popper-card-child-top {
  border-top: solid 1px $teal-lighter;
  padding: 18px 80px 18px 22px;
}

.card-header {
  background: white;
}

.rupee-container {
  position: relative;
  height: 42px;
}

.rupee-span {
  position: absolute;
  height: 100%;
  border: solid 1px #a5cfcf;
  border-radius: 8px 0px 0px 8px;
  background: #eff1f3;
  width: 40px;
}

.rupee-input {
  height: 100%;
  border: solid 1px #a5cfcf;
  width: 100%;
  text-indent: 50px;
  font-family: Gotham-Medium;
  font-size: 14px;
  line-height: 1;

  &::placeholder {
    color: $ink-lighter;
    font-family: Gotham-Medium;
    font-size: 14px;
    line-height: 1;
    opacity: 0.46;
  }
}

.rupee-image {
  position: relative;
  top: 7px;
  left: 7px;
}

// .basic{
//   div.sub__control{
//    border:solid 1px white;
//    border-radius:8px;
//    background-color:white;
//    height:42px;
//    list-style: none;
//   }
//  }

//  .clicked{
//   .sub__indicators{
//     background-image: url('./assets/images/up.png') !important;
//   }
//  }

//  .sub__indicators{
//    border-radius:0px 8px 8px 0px;
//    width: 40px;
//    background-image: url('./assets/images/dropdown.png');
//    background-color:white !important;
//    background-repeat: no-repeat !important;
//    background-position: center !important;
//    span{
//      margin:0px;
//      border:solid 0.5px white
//    }
//    .sub__indicator{
//    color:gray;

//    svg{
//      display: none
//    }
//  }

//  }

.basic {
  div.sub__control {
    border: solid 0.5px #bbc0c6;
    border-radius: 4px;
    background-color: transparent;
    height: 30px;
  }
}

.sub__indicators {
  background: $sky-lighter;
  border-radius: 0px 8px 8px 0px;
  span {
    margin: 0px;
    border: solid 0.5px $teal-lighter;
  }
  .sub__indicator {
    color: gray;
  }
  .sub__placeholder {
    color: $ink-lighter;
  }
}

.store-popper {
  width: 100%;
  display: block;
}
.store-popper-card-parent {
  display: block;
  width: 100%;
  height: auto;
  border-bottom: 1px solid $teal-lighter;
}

.store-popper-card-child {
  display: inline;
  width: 40%;
}

.store-popper-card-child:first {
  padding-right: 5%;
}

.popup-table {
  min-width: 600px;
}

.custom-btn {
  border-radius: 8px;
  background: transparent;
  color: $blue;
  border-color: $blue;
  padding: 13px 24px;
  line-height: 0.9;
  font-size: 14px;
  letter-spacing: -0.2px;
}

.dotted-border {
  border-bottom: 0.5px dashed $teal-lighter !important;
}

$zigzag-height: 16px;
$zigzag-halfheight: ($zigzag-height/2);
$zigzag-thickness: 110%; // increase to make the line thicker
$zigzag-offset: 4px;
$zigzag-backgroundcolor: $white;
$zigzag-linecolor: $white;

.zigzag {
  margin: 0%;
  background: $zigzag-backgroundcolor;
  position: relative;
  height: $zigzag-height;
  z-index: 1;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }
  &:before {
    height: ($zigzag-height - $zigzag-offset);
    top: $zigzag-thickness;
    background: linear-gradient(
          -135deg,
          $zigzag-linecolor $zigzag-halfheight,
          transparent 0
        )
        0 $zigzag-halfheight,
      linear-gradient(
          135deg,
          $zigzag-linecolor $zigzag-halfheight,
          transparent 0
        )
        0 $zigzag-halfheight;
    background-position: top left;
    background-repeat: repeat-x;
    background-size: $zigzag-height $zigzag-height;
  }
  &:after {
    height: $zigzag-height;
    top: 100%;
    background: linear-gradient(
          -135deg,
          $zigzag-backgroundcolor $zigzag-halfheight,
          transparent 0
        )
        0 $zigzag-halfheight,
      linear-gradient(
          135deg,
          $zigzag-backgroundcolor $zigzag-halfheight,
          transparent 0
        )
        0 $zigzag-halfheight;
    background-position: top left;
    background-repeat: repeat-x;
    background-size: $zigzag-height $zigzag-height;
  }
}

.table-modal-wrapper {
  padding-top: 10%;
}

.custom-modal-body {
  padding-top: 0 !important;
}

.modal-innerbox {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: $sky-lightest;
  padding: 10px;
  width: 128px;
  height: 94px;
  border-radius: 4px;
  cursor: pointer;
}

.box-active {
  background-color: $blue-lightest;
  border: 1px solid $blue;
}

.focused {
  background-color: $blue-lightest;
  border: 1px solid $blue;
}
.scattered {
  background-color: $sky-lightest;
  border: none;
}

.modal-font {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: #212b36;
  display: block;
}
.modal-font-child {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: #575655;
  display: block;
}

///////////multiselect overiding classes starts//////////
.css-1r4vtzz {
  font-size: 1rem !important;
  border: 0.5px solid #bbc0c6 !important;
  border-radius: 4px !important;
  height: 30px !important;
  width: 100% !important;
  color: #808080 !important;
  margin-bottom: 5px !important;
  box-shadow: none !important;
  padding: 0px 8px !important;
}

.css-48ayfv {
  font-size: 1rem !important;
  border: 0.5px solid #bbc0c6 !important;
  border-radius: 4px !important;
  height: 30px !important;
  width: 100% !important;
  color: #808080 !important;
  margin-bottom: 5px !important;
  box-shadow: none !important;
  padding: 0px 8px !important;
}

.css-ik6y5r {
  margin-left: 3% !important;
  width: 88% !important;
  box-shadow: none !important;
  margin-top: -10px !important;
}

.css-1jllj6i-control {
  display: none !important;
}

.css-11unzgr {
  overflow-x: hidden;
  max-height: 180px !important;
}
///////////multiselect overiding classes ends//////////

.table thead th {
  //table in edit memberships
  font-size: 16px;
  font-weight: normal;
  color: #6b7783;
  border-bottom: 1px solid #efefef;
}

.table td,
.table th {
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  font-weight: 500;
}

//scroll bar in edit memberships

#customScroll-y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#customScroll-y::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#customScroll-y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightblue;
}

//timepicker
.time-pick {
  width: 100%;
  border-radius: 4px;
  //border: solid 0.5px #bbc0c6;
  height: 36px;
  color: #242424 !important;
}

.rc-time-picker-input {
  // width: 100%;
  color: #242424 !important;
  height: 36px !important;
  border-radius: 4px !important;
  border: 1px solid #cfcfd4 !important;
  font-size: 14px !important;
}

//printreceipt
.receipt-container {
  margin-top: 20px;
  margin-right: 20px;
  padding: 15px;
}

.receipt-content {
  background: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
}

.receipt-p-text {
  color: #6b7783;
  //font-family: none;
  // font-family: "Courier New", Courier, monospace;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.17px;
}

.mg-top-bottom-20x {
  margin-top: 20px;
  margin-bottom: 20px;
}

.receipt-heading {
  font-size: 20px;
  color: #6b7783;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.receipt-sub-heading {
  font-size: 16px;
  color: #6b7783;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashed-line {
  border-top: 2px dashed #6b7783;
}

//sms-receipt

.smspreview-container {
  margin-top: 20px;
  margin-left: 20px;
  padding: 15px;
}

.sms-p-text {
  color: black;
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  margin-bottom: 2px;
}

.popup-card {
  background: #fafafa;
  margin-top: 20px;
  padding: 15px;
}

.preview-footer {
  position: fixed;
  width: 100%;
  height: 120px;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 0px;
  box-shadow: 0 -5px 10px -5px #c9c9c9;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.popover {
  max-width: 316px;
}

// create service table formatter
.createServiceInput {
  border-radius: 0 4px 4px 0 !important;
}

//cart
.cart-info-text {
  //font-family: Gotham-Book;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink-lighter;
  display: block;
}

.cart-info-text-2 {
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink-mild;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  // cursor: pointer;
}

.react-bootstrap-table th .order:before {
  margin-left: 3.5px !important;
  content: "\2191" !important;
  opacity: 0.4 !important;
}

.react-bootstrap-table th .order:after {
  content: "\2193" !important;
  opacity: 0.4 !important;
}

.react-bootstrap-table-sort-order .caret:before {
  margin-left: 3.5px !important;
  content: "\2191" !important;
  opacity: 1;
}

.react-bootstrap-table-sort-order .caret:after {
  content: "\2193" !important;
  opacity: 0.4;
  margin-left: -2px;
}

.react-bootstrap-table-sort-order.dropup .caret:before {
  margin-left: 3.5px !important;
  content: "\2191" !important;
  opacity: 0.4;
}

.react-bootstrap-table-sort-order.dropup .caret:after {
  content: "\2193" !important;
  opacity: 1;
  margin-left: -2px;
}
///////////////////////////////////checkbox custom////////////////////

.casket {
  display: block;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.casket input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  border: 2px solid #d8d8d8;
}
.casket:hover input ~ .checkmark {
  background-color: #ccc;
}
.casket input:checked ~ .checkmark {
  background-color: #f9f1c4;
  border: 2px solid #dca849;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.casket input:checked ~ .checkmark:after {
  display: block;
}
.casket .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #dca849;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
////////////////////////////////////////////

.Spinner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
}

.react-tagsinput {
  min-height: 36px !important;
  padding: 8px !important;
  border-radius: 4px !important;
  border: solid 1px #cfcfd4 !important;
}

.react-tagsinput-tag {
  height: 32px !important;
  padding: 0px !important;
  padding-top: 4px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-radius: 16px !important;
  background-color: $blue !important;
  border: 1px solid $blue !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #ffffff !important;
  font-family: none !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}

.react-tagsinput-input {
  width: 150px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 16px !important;
  padding: 0px !important;
}
